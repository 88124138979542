import React from 'react';
import { Row, Col } from 'react-bootstrap';

// ImageContainer component
const ImageContainer = ({ src, alt, width, height, className, style, title }) => {
  return (
    <>
      <Row className="mb-4">
        <Col lg={12}>
          <div className="text-center iq-title-box iq-title-default iq-title-box-2">
            <h2 className="iq-title">{title}</h2>
          </div>
        </Col>
      </Row>
      
      <Row className="justify-content-center mb-4">
        <Col xs={12} sm={10} md={8} lg={6} className="text-center">
          <div
            className={`image-container ${className || ''}`}
            style={{ width: width || '100%', height: height || 'auto', ...style }}
          >
            <img
              src={src}
              alt={alt || 'Image'}
              className="img-fluid"
              style={{
                borderRadius: '8px', // Optional styling
              }}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ImageContainer;
