import React,  { useState, useContext, useEffect,useRef }  from 'react';
// import Navbar from '../components/Navbar'
import { DataContext } from '../context'
import Footer from '../components/Footer';
import axios from 'axios';
// import moment from 'moment';
import {  FaEye, FaEdit, FaTrashAlt, FaFlag, FaTimes, FaLeaf , FaChevronDown } from 'react-icons/fa';
// import { TRUE } from 'node-sass';
import { useHistory } from 'react-router-dom';
import './PagesCss.css'
import { Col, Container, Form, Button, Row, Navbar } from 'react-bootstrap';
// import { FaMapMarkerAlt } from 'react-icons/fi';
import logo from "../images/banner_dark.svg";
import {FaPaperPlane , FaClock, FaMapMarkerAlt, FaBriefcase} from 'react-icons/fa';
import { FiHome } from 'react-icons/fi';




const AdminHome = () => {
  const context = useContext(DataContext);
  const {footer,VMservice } = context.allData
  const [OpeningsData, setOpeningsData] = useState([])
  const [CareerData, setCareerData] = useState([])
  const [partnerData , setpartnerData] = useState(null)

  const history = useHistory();


  const [editMode, seteditMode] = useState(false)

  const { handleSearch, handleOffcanvas } = context;

//   const today = moment().startOf('day'); ;

//   console.log(today)
useEffect(() => {
  const LoggedIn = sessionStorage.getItem('isLoggedIn');

      if (!LoggedIn) {
        history.push('/login');
      }
    }, [history]);


  // const containerStyle = {
  //   display: 'flex',
  //   flexWrap: 'wrap',
  //   marginLeft:'150px',
  //   marginRight:'150px',
  //   marginTop:'50px',
  //   marginBottom:'50px',
  //    justifyContent:'center'

  // };

  // const blockStyle = {
  //   backgroundColor: 'white',
  //   width: 'calc(25% - 20px)',
  //   height: 'auto',
  //   margin: '10px',
  //   padding: '20px',
  //   display: 'flex',
  //   flexDirection: 'column',
  //   justifyContent: 'space-between',
  //   alignItems: 'flex-start',
  //   color: 'black',
  //   boxSizing: 'border-box',
  //   borderRadius: '10px',
  //   boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  //   border: '1px solid #e0e0e0',
  //   // cursor: 'pointer',
  //   position: 'relative' // Added position: relative
  // };

  // const deleteButtonStyle = {
  //   position: 'absolute',
  //   top: '75px',
  //   right: '15px',
  //   margin: 0,
  //   color: 'red',
  //   cursor: 'pointer',
  //   width:'16px',
  //   height:'16px'
  // };

  // const viewButtonStyle = {
  //   position: 'absolute',
  //   top: '20px',
  //   right: '15px',
  //   margin: 0,
  //   color: 'green',
  //   cursor: 'pointer',
  //    width:'18px',
  //    height:'18px'
  // };

  // const EditButtonStyle = {
  //   position: 'absolute',
  //   top: '45px',
  //   right: '13px',
  //   margin: 0,
  //   color: 'green',
  //   cursor: 'pointer',
  //   width:'18px',
  //   height:'18px'
  // };


  useEffect(() => {
    fetchData();
  }, []);

const fetchData= () => {

    axios.get('https://trivont.com/api/openings/view/')
      .then(response => setOpeningsData(response.data))
      .catch(error => console.log(error));


    axios.get('https://trivont.com/api/career/view/')
    .then(response => setCareerData(response.data))
    .catch(error => console.log(error));



  };

  const refreshData =() => {
    fetchData()
  }

  const handleAdminView = (id) =>{


    const data = {

      is_Active: false,
      is_Visible: false,

  };

    axios.put(`https://trivont.com/api/openings/edit/view/${id}/`, data)
        .then(response => {
            // console.log(response);
            if(response.data.status === 'success') {
                alert("Data Successfully Modified/Deleted")
                seteditMode(false)
                setpartnerData("")
                refreshData()

            } else {
                alert("An error occurred, please try again later.");
            }
        })
        .catch(error => { 
          console.log(error);
           alert("An error occurred, please try again later.")
          });




  }


  const summaryStyle = {
    paddingLeft: '20px',
    paddingRight:'300px',
    marginTop:'30px',
    marginBottom: '50px',
    // border: '1px solid #e0e0e0',
    borderRadius: '10px',
    backgroundColor: '#f9f9f9',
    paddingTop:'10px',
    paddingBottom:'10px'
    // boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  };


  const handleAdminViewEdit = (id) => {
    const selectedOpening = OpeningsData.find(opening => opening.id === id);
    history.push({
      pathname: '/openings',
      state: { selectedOpening } // Pass data as state
    });
  };


  const handelUserDelete = (id) =>{


    const data = {

      is_Active: false,


  };

    axios.put(`https://trivont.com/api/career/edit/active/${id}/`, data)
        .then(response => {
            // console.log(response);
            if(response.data.status === 'success') {
                alert("Data Successfully Deleted!")
                refreshData();

            } else {
                alert("An error occurred, please try again later.");
            }
        })
        .catch(error => { 
          console.log(error); 
          alert("An error occurred, please try again later.")
        });

  }


  const handelSceen = (id) => {



    axios.put(`https://trivont.com/api/career/edit/sceen/${id}/`)
        .then(response => {
            // console.log(response);
            if (response.data.status === 'success') {
                // alert("Data Successfully Modified");
                refreshData()
            } else {
                alert("An error occurred, please try again later.");
            }
        })
        .catch(error => { 
          console.log(error);
           alert("An error occurred, please try again later.")
          });
}



  const handleCareerView = (partner) => {
    seteditMode(true)
    setpartnerData(partner)
  }

  const allOpenings = () => {
    seteditMode(false)
    setpartnerData("")
  }

  const newOpening = () => {
    history.push('/openings')
  }


  const textareaRef1 = useRef(null);
  const textareaRef2 = useRef(null);
  const textareaRef3 = useRef(null);
  const textareaRef4 = useRef(null);
  const textareaRef5 = useRef(null);

  useEffect(() => {
    if (partnerData && partnerData.skills) {
      const adjustHeight1 = () => {
        const textarea = textareaRef1.current;
        if (textarea) {
          textarea.style.height = 'auto'; 
          textarea.style.height = `${textarea.scrollHeight}px`; 
        }
      };
      adjustHeight1(); 
    }
    if (partnerData && partnerData.qualifications) {
      const adjustHeight2 = () => {
        const textarea = textareaRef2.current;
        if (textarea) {
          textarea.style.height = 'auto'; 
          textarea.style.height = `${textarea.scrollHeight}px`; 
        }
      };
      adjustHeight2(); 
    }
    if (partnerData && partnerData.responsibilities) {
      const adjustHeight3 = () => {
        const textarea = textareaRef3.current;
        if (textarea) {
          textarea.style.height = 'auto'; 
          textarea.style.height = `${textarea.scrollHeight}px`; 
        }
      };
      adjustHeight3(); 
    }
    if (partnerData && partnerData.benefits) {
      const adjustHeight4 = () => {
        const textarea = textareaRef4.current;
        if (textarea) {
          textarea.style.height = 'auto'; 
          textarea.style.height = `${textarea.scrollHeight}px`; 
        }
      };
      adjustHeight4(); 
    }
    if (partnerData && partnerData.summery) {
      const adjustHeight5 = () => {
        const textarea = textareaRef5.current;
        if (textarea) {
          textarea.style.height = 'auto'; 
          textarea.style.height = `${textarea.scrollHeight}px`; 
        }
      };
      adjustHeight5(); 
    }
  }, [partnerData]);



  return (
  <>
<div className="page-container">
<Navbar className="bg-body-tertiary mt-3">
        <Container>
          <Navbar.Brand href="#home">
            <img
              alt=""
              src={logo}
              width="200"
              height="30"
              className="d-inline-block align-top"
            />
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Navbar.Text>
              <a href="/">
                <FiHome style={{ fontSize: '24px' }} /> Back to Home
              </a>
            </Navbar.Text>
          </Navbar.Collapse>
        </Container>
      </Navbar>

<div className="content">
 { !editMode &&
 <>
 <br/>
 <div className="d-flex justify-content-end" style={{marginRight:'30px'}}>
 <button onClick={newOpening} style={{padding:'5px', backgroundColor:'#007bff', color:'white', boxShadow:'0px 2px 4px rgba(0,0,0,0.1)', borderRadius:'5px'}}>New Opening</button>
  </div>
  {/* <FaEye onClick={() => handleCareerView(partner)}  className='viewButtonStyle'/>
      <FaEdit onClick={() => handleAdminViewEdit(partner.id)}  className='EditButtonStyle'/>
      <FaTrashAlt onClick={() => handleAdminView(partner.id)}  className='deleteButtonStyle'/>
     */}


  <div className="career-container">
    {OpeningsData.filter(partner => partner.is_Visible === true).map((partner, index) => (

      <div key={index} className="career-block" style={{cursor:'pointer', transition: 'transform 0.2s ease, box-shadow 0.2s ease'}} 
      onMouseEnter={(e) => {
        e.currentTarget.style.transform = 'scale(0.98)';
        e.currentTarget.style.boxShadow = '0px 4px 8px rgba(0, 0, 0, 0.2)';
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.transform = 'scale(1)';
        e.currentTarget.style.boxShadow = 'none';
      }}
      onClick={() => handleCareerView(partner)}>
      <h3 className="job-title">{partner.domain}</h3>
      <h4 className="job-subtitle">{partner.title.split('-')[0].trim()}</h4>

      
      <p className="job-meta">
        <p style={{marginBottom:'5px'}}>Req ID: {partner.reqId}</p>
        <p style={{marginBottom:'5px'}}><FaBriefcase className="icon12345"/>Experience: {partner.experience}</p>
        <p style={{marginBottom:'20px'}}><FaMapMarkerAlt className="icon12345"/>Location: {partner.location}</p>
        <p style={{ margin: '0 0 10px 0' }}>Number of Applications : {CareerData.filter(partnerCareer => partnerCareer.opening_id === parseInt(partner.id, 10)).length}</p>
      </p>

         </div>

         


    ))}

  </div>
  <br/>
  </>
}

{ editMode &&
 <>
<br/>
{/* <div className="d-flex justify-content-end" style={{marginRight:'30px'}}>
      <button onClick={allOpenings} style={{ border:'1px solid black', borderRadius:'5px', padding:'5px'}}>View All Openings</button>
      </div> */}
      <br/> 

      <div class="career-details" style={{marginBottom:'60px', marginRight:'170px', marginLeft:'150px'}}>

      <div style={{marginBottom:'35px'}}>
        <h3> {partnerData.title} </h3>
        {/* style={{width:'1000px'}} */}

          {/* <div class="career-button-container" style={{display:'flex', top:'-30px',borderRadius:'3px', gap:'10px' ,justifyContent:'right', marginBottom:'0px'}}>
            <button class="career-button" onClick={() => handleAdminViewEdit(partnerData.id)}>Edit</button>
            <button class="career-button" onClick={() => handleAdminView(partnerData.id)}>Delete</button>
          </div> */}

      </div>

        <div class="job-details" style={{display:'flex', justifyContent:'space-between'}}>
          <div class="job-info" >
            <p><strong class="label123" style={{marginRight:'20px', color:'black'}}>Total Experience:</strong> { partnerData.experience}</p>
            <p><strong class="label123" style={{marginRight:'40px', color:'black'}}>Job Post Date:</strong> { new Date(partnerData.date_created).toLocaleDateString() }</p>
            <p><strong class="label123" style={{marginRight:'10px', color:'black'}}>Job Reference No:</strong> { partnerData.reqId }</p>

            
          </div>
          <div class="job-info" >
          <p><strong class="label123" style={{marginRight:'70px', color:'black'}}>Location:</strong> { partnerData.location }</p>
          <p><strong class="label123" style={{marginRight:'15px', color:'black'}}>Job Expiry Date:</strong> { new Date(partnerData.last_date ).toLocaleDateString() }</p>


            {/* { partnerData.aountSalary && (
            <p><strong class="label123" style={{marginRight:'32px'}}>Salary:</strong> { partnerData.aountSalary }</p>
            )} */}

          </div>

          <div class="job-info">

          <p><strong class="label123" style={{marginRight:'87px', color:'black'}}>Domain:</strong> { partnerData.domain }</p>
          <p><strong class="label123" style={{marginRight:'27px', color:'black'}}>No of Openings:</strong> { partnerData.number_of_openings }</p>



          </div>

        </div>
        <div class="job-description">
<>
        <h4 style={{marginBottom:'10px'}}>Required Skills</h4>
        </>
        
        <textarea ref={textareaRef1} type="text" class="form-control" value={partnerData.skills} readonly style={{border:'none',backgroundColor:'white'}}/>


          {partnerData.qualifications && (
            <>
            <>
            <h4 style={{marginBottom:'10px'}}>Preferred Qualifications</h4>
            </>
            <textarea ref={textareaRef2} type="text" class="form-control" value={partnerData.qualifications} readonly style={{border:'none',backgroundColor:'white'}}/>
            </>
          )}

          {partnerData.responsibilities && (
            <>
            <>
            <h4 style={{marginBottom:'10px'}}>Key Responsibilities</h4>
            </>
            <textarea ref={textareaRef3} type="text" class="form-control" value={partnerData.responsibilities} readonly style={{border:'none',backgroundColor:'white'}}/>
            </>
          )}
          
        </div>

        <div class="career-button-container" style={{gap:'10px'}}>
        <button class="career-button" onClick={() => handleAdminViewEdit(partnerData.id)} style={{marginRight:'10px'}}>Edit</button>
          <button class="career-button" style={{marginRight:'10px'}} onClick={allOpenings}>VIEW ALL OPENINGS</button>

        </div>

        <div class="job-benefits">
        {partnerData.benefits && (
            <>
            <>
          <h3 style={{marginBottom:'15px'}}>Compensation and Benefits</h3>
          </>
          <textarea ref={textareaRef4} type="text" class="form-control" value={partnerData.benefits} readonly style={{border:'none',backgroundColor:'white'}}/>
          </>
        )}
        <>
          <h3 style={{marginBottom:'20px'}}>Job Summary</h3>
          </>
          <textarea ref={textareaRef5} type="text" class="form-control" value={partnerData.summery} readonly style={{border:'none',backgroundColor:'white'}}/>
        </div>

        <button class="career-button" onClick={() => handleAdminView(partnerData.id)}>Delete</button>
      </div>

      {/* {(CareerData.filter(partnerCareer => partnerCareer.opening_id === parseInt(partner.id, 10)).length > 0) && ( */}
      {CareerData.filter(partner => partner.opening_id === partnerData.id && partner.is_Active === true ).length > 0 && (
        <>
        <h2 style={{marginBottom:'10px', marginLeft:'120px'}}>Applications Received</h2>
      <table className="table table-striped table-bordered" id='userTaniyangi' style={{borderRight:'none', borderLeft:'none', width:'1350px', marginLeft:'120px', marginRight:'10px'}}>
                          <thead className="thead-dark" >
                            <tr>

                              <th style={{backgroundColor:'white', color:'black', borderRight:'none', borderLeft:'none'}}>Id</th>
                              <th style={{backgroundColor:'white', color:'black', borderRight:'none', borderLeft:'none'}}>Name</th>
                              <th style={{backgroundColor:'white', color:'black', borderRight:'none', borderLeft:'none'}}>Email</th>
                              <th style={{backgroundColor:'white', color:'black', borderLeft:'none', borderRight:'none'}}>Mobile</th>
                              <th style={{backgroundColor:'white', color:'black', borderLeft:'none', borderRight:'none'}}>File</th>
                              <th style={{backgroundColor:'white', color:'black', borderLeft:'none', borderRight:'none'}}>Flag</th>

                              <th style={{backgroundColor:'white', color:'black', borderLeft:'none', borderRight:'none'}}>Action</th>

                            </tr>
                          </thead>
                          <tbody>
                            
                            {CareerData.filter(partner => partner.opening_id === partnerData.id && partner.is_Active === true).map((partner, index) => (
                              <tr key={index}>
                                <td style={{borderRight:'none', borderLeft:'none'}}>{index + 1}</td>
                                <td style={{borderRight:'none', borderLeft:'none'}}>{partner.first_name}</td>
                                <td style={{borderRight:'none', borderLeft:'none'}}>{partner.email}</td>
                                <td style={{borderRight:'none', borderLeft:'none'}}>{partner.mobile}</td>
                                <td style={{ borderRight: 'none', borderLeft: 'none' }}>

                                  {partner.file_path && partner.file_path.endsWith('.pdf') ? (
                                    <a href={`https://trivont.com/api/media/${partner.file_path}`} target="_blank" rel="noopener noreferrer">View PDF</a>
                                  ) : partner.file_path && partner.file_path.match(/\.(jpg|jpeg|png)$/) ? (
                                    <img src={`https://trivont.com/api/media/${partner.file_path}`} alt="file" style={{ width: '100px', height: 'auto' }} />
                                  ) : (
                                    <a href={`https://trivont.com/api/media/${partner.file_path}`} target="_blank" rel="noopener noreferrer">View File</a>
                                  

                                  )}

                                </td>
                                
                                <td style={{borderRight:'none', borderLeft:'none', width:'30px', cursor:'pointer'}} onClick={() => handelSceen(partner.id)}> {partner.is_sceen === true ? '🚩' : '⚑' }
                              </td>

                                <td style={{borderRight:'none', borderLeft:'none', width:'30px'}}><FaTrashAlt onClick={(e) => handelUserDelete(partner.id)} style={{cursor:'pointer'}}/>
                                </td>
                                                               
                              </tr>
                            ))}
                          
                          
                          </tbody>
      </table>
      </>
      )}
      {/* )} */}
<br/>
 </>
}
   </div>






    <Footer data={footer} className="footer_career" />
  </div>
  </>
  )
}

export default AdminHome
