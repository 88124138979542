import React,  { useState, useContext, useEffect, useRef }  from 'react';
import Navbar from '../components/Navbar'
import { DataContext } from '../context'
import Footer from '../components/Footer';
import BannerSectionCareer from '../components/customComponents/bannerSectionCareer'
import CyberS from '../images/BANNERS/careerImage.jpg'
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import {FaPaperPlane , FaClock, FaMapMarkerAlt, FaBriefcase} from 'react-icons/fa';

import './PagesCss.css'


const Career = () => {
  const context = useContext(DataContext);
  const {footer,VMservice } = context.allData
  const [OpeningsData, setOpeningsData] = useState([])

  const { handleSearch, handleOffcanvas } = context;

  const [selectedData, setSelectedData] = useState(null);
  const [editForm, setEditForm] = useState(false);
  const history = useHistory();

//   const careercontainerStyle = {
//     display: 'flex',
//     flexWrap: 'wrap',
//     marginLeft:'150px',
//     marginRight:'150px',
//     marginTop:'50px',
//      marginBottom:'50px',
//      justifyContent:'center'

//   };

//   const careerblockStyle = {
//     backgroundColor: 'white',
//     width: 'calc(25% - 20px)', // fixed width for 4 items per row minus margin
//     height: 'auto', // adjust height to fit content
//     margin: '10px', // spacing between blocks
//     padding: '20px', // inner padding
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent: 'space-between',
//     alignItems: 'flex-start',
//     color: 'black', // text color
//     boxSizing: 'border-box', // include padding and border in width and height
//     borderRadius: '10px', // curved edges
//     boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // box shadow
//     border: '1px solid #e0e0e0',

//   };

// const careerbuttonStyle = {
//     marginTop: '20px',
//     padding: '10px 20px',
//     border: '1px solid black',
//     borderRadius: '5px',
//     backgroundColor: 'white',
//     color: 'black',
//     cursor: 'pointer',
//     textAlign: 'center',
//   };

  const handelClick = (id) => {
    history.push({
      pathname: '/careerReg',
      state : {jobId : id},
    })
  }

  useEffect(() => {
    fetchData();
  }, []);

const fetchData= () => {

    axios.get('https://trivont.com/api/openings/view/')
      .then(response => setOpeningsData(response.data))
      .catch(error => console.log(error));


  };

  

  const handleView = (partner) => {
    setEditForm(true)
    setSelectedData(partner)
  }

  const handelBack =() =>{
    setEditForm(false)
  }


  const textareaRef1 = useRef(null);
  const textareaRef2 = useRef(null);
  const textareaRef3 = useRef(null);
  const textareaRef4 = useRef(null);
  const textareaRef5 = useRef(null);

  useEffect(() => {
    if (selectedData && selectedData.skills) {
      const adjustHeight1 = () => {
        const textarea = textareaRef1.current;
        if (textarea) {
          textarea.style.height = 'auto'; 
          textarea.style.height = `${textarea.scrollHeight}px`; 
        }
      };
      adjustHeight1(); 
    }
    if (selectedData && selectedData.qualifications) {
      const adjustHeight2 = () => {
        const textarea = textareaRef2.current;
        if (textarea) {
          textarea.style.height = 'auto'; 
          textarea.style.height = `${textarea.scrollHeight}px`; 
        }
      };
      adjustHeight2(); 
    }
    if (selectedData && selectedData.responsibilities) {
      const adjustHeight3 = () => {
        const textarea = textareaRef3.current;
        if (textarea) {
          textarea.style.height = 'auto'; 
          textarea.style.height = `${textarea.scrollHeight}px`; 
        }
      };
      adjustHeight3(); 
    }
    if (selectedData && selectedData.benefits) {
      const adjustHeight4 = () => {
        const textarea = textareaRef4.current;
        if (textarea) {
          textarea.style.height = 'auto'; 
          textarea.style.height = `${textarea.scrollHeight}px`; 
        }
      };
      adjustHeight4(); 
    }
    if (selectedData && selectedData.summery) {
      const adjustHeight5 = () => {
        const textarea = textareaRef5.current;
        if (textarea) {
          textarea.style.height = 'auto'; 
          textarea.style.height = `${textarea.scrollHeight}px`; 
        }
      };
      adjustHeight5(); 
    }
  }, [selectedData]);



  return (
  <>
  <Navbar handler={{handleSearch, handleOffcanvas}} headerTop="True"  />

 { !editForm && (
  <>
  <BannerSectionCareer title="Current Job Openings" backgroundColor="#007bff" backgroundImage={CyberS} color="#fff" height="600px" customBackgroundSize='auto'/>

  <div className="career-container">
  {/* {OpeningsData.filter(partner => partner.is_Active === true).length > 0 ? ( */}
  {OpeningsData.filter(partner => partner.is_Visible).length > 0 ? (
        // OpeningsData.filter(partner => partner.is_Active === true ).map((partner, index) => (
    OpeningsData.filter(partner => partner.is_Visible).map((partner, index) => (
      
      <div key={index} className="career-block">
      <h3 className="job-title">{partner.domain}</h3>
      <h4 className="job-subtitle">{partner.title.split('-')[0].trim()}</h4>
      <p className="job-meta">
        <p style={{marginBottom:'5px'}}>Req ID: {partner.reqId}</p>
        <p style={{marginBottom:'5px'}}><FaBriefcase className="icon12345"/>Experience: {partner.experience}</p>
        <p style={{marginBottom:'20px'}}><FaMapMarkerAlt className="icon12345"/>Location: {partner.location}</p>
      </p>
      <div className="job-action">
        <button className="apply-button"  onClick={() => handleView(partner)}>
          Apply Now
          <FaPaperPlane className="icon123" />
        </button>
      </div>
    </div>

    ))
  ) : (
    <p>No new openings available</p>
  )}
  </div>
  </>
 )}

{ editForm && (
  <>
  <BannerSectionCareer title="Job Description" backgroundColor="#007bff" backgroundImage={CyberS} color="#fff" height="600px" customBackgroundSize='auto'/>



       <>

        {/* <div className='row' style={{display:'flex', justifyContent:'right', marginRight:'30px'}}>
       <buttton className="career-button" onClick={handelBack}>All Openings</buttton>
       </div> */}

      <div class="career-details">
        <h3 style={{marginBottom:'35px'}}>{selectedData.title}</h3>
        <div class="job-details" style={{display:'flex', justifyContent:'space-between'}}>
          <div class="job-info" >
            <p><strong class="label123" style={{marginRight:'20px', color:'black'}}>Total Experience:</strong> { selectedData.experience}</p>
            <p><strong class="label123" style={{marginRight:'40px', color:'black'}}>Job Post Date:</strong> { new Date(selectedData.date_created).toLocaleDateString() }</p>
            <p><strong class="label123" style={{marginRight:'10px', color:'black'}}>Job Reference No:</strong> { selectedData.reqId }</p>

            
          </div>
          <div class="job-info" >
          <p><strong class="label123" style={{marginRight:'70px', color:'black'}}>Location:</strong> { selectedData.location }</p>
          <p><strong class="label123" style={{marginRight:'15px', color:'black'}}>Job Expiry Date:</strong> { new Date(selectedData.last_date ).toLocaleDateString() }</p>


            {/* { selectedData.aountSalary && (
            <p><strong class="label123" style={{marginRight:'32px'}}>Salary:</strong> { selectedData.aountSalary }</p>
            )} */}

          </div>

          <div class="job-info">

          <p><strong class="label123" style={{marginRight:'87px', color:'black'}}>Domain:</strong> { selectedData.domain }</p>
          <p><strong class="label123" style={{marginRight:'27px', color:'black'}}>No of Openings:</strong> { selectedData.number_of_openings }</p>



          </div>

        </div>
        <div class="job-description">
  <>
        <h4 style={{marginBottom:'10px'}}>Required Skills</h4>
        </>
        
        <textarea ref={textareaRef1} type="text" class="form-control" value={selectedData.skills} readonly style={{border:'none',backgroundColor:'white'}}/>

          {selectedData.qualifications && (
            <>
            <>
            <h4 style={{marginBottom:'10px'}}>Preferred Qualifications</h4>
            </>
            <textarea ref={textareaRef2} type="text" class="form-control" value={selectedData.qualifications} readonly style={{border:'none',backgroundColor:'white'}}/>
            </>
          )}

          {selectedData.responsibilities && (
            <>
            <>
            <h4 style={{marginBottom:'10px'}}>Key Responsibilities</h4>
            </>
            <textarea ref={textareaRef3} type="text" class="form-control" value={selectedData.responsibilities} readonly style={{border:'none',backgroundColor:'white'}}/>
            </>
          )}
          
        </div>
        <div class="career-button-container">
          <button class="career-button" style={{marginRight:'10px'}} onClick={() => handelClick(selectedData.id)}>APPLY NOW</button>
          {/* <button class="career-button"  onClick={() => handelClick(selectedData.id)}>RECOMMEND TO FRIEND</button> */}

        </div>

        <div class="job-benefits">
        {selectedData.benefits && (
            <>
            <>
          <h3 style={{marginBottom:'15px'}}>Compensation and Benefits</h3>
          </>
          <textarea ref={textareaRef4} type="text" class="form-control" value={selectedData.benefits} readonly style={{border:'none',backgroundColor:'white'}}/>
          </>
        )}
        <>
          <h3 style={{marginBottom:'20px'}}>Job Summary</h3>
          </>
          <textarea ref={textareaRef5} type="text" class="form-control" value={selectedData.summery} readonly style={{border:'none',backgroundColor:'white'}}/>
        </div>
      </div>

      </>

  </>
 )}









    <Footer data={footer} />

  </>
  )
}

export default Career
