import React, { useContext } from 'react'
import { DataContext } from '../context'
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';

import BSsection4s from '../components/BSsection4';

import './PagesCss.css'

import SliderHeroSection from '../components/customComponents/SliderHeroSection';
import AnimatedCardGrid from '../components/customComponents/animatedGrid';
import IconBox from '../components/iconbox';
import autoone1 from '../images/icons/setting_3470524.png';
import autoone2 from '../images/icons/compliance_4460533.png';
import autoone3 from '../images/icons/versatility_7229121.png';
import autoone4 from '../images/icons/cloud-computing_1136779.png';
import autoone5 from '../images/icons/automation_12122355.png';
import autoone6 from '../images/icons/hypothesis_12489513.png';
import autoone7 from '../images/icons/carbon-footprint_4694811.png';
import autoone8 from '../images/icons/engineering_3079165.png';
import autoone9 from '../images/icons/gear.png';
import autoone10 from '../images/icons/robust.png';
import ImageContainer from '../components/customComponents/imageContainer';
import taniyangi from '../images/media/Taniyangi.jpg'


function AutomationAI() {

    const context = useContext(DataContext);
    const { footer,Column11, DMservice} = context.allData
    const { handleSearch, handleOffcanvas } = context;

  

  const features = [
    {
      "title": "Configuration Management and Orchestration",
      image: autoone1,
      "subtitle": "Your Comprehensive Solution for Configuration Management and Orchestration",
      "description": "Trivont Automation comes packed with the capabilities to perform configuration management similar to Ansible and orchestration similar to Terraform. This means you can not only manage your system configurations efficiently but also automate complex deployment processes with ease."
    },
    {
      "title": "Audit Compliance & Security",
      image: autoone2,
      "subtitle": "Ensuring Compliance and Security in Your Operations",
      "description": "We understand the importance of compliance and security in your operations. With Trivont Automation, you can rest assured that all your automation tasks are audit compliant, giving you peace of mind and ensuring your operations meet industry standards."
    },
    {
      "title": "Versatility in Deployment",
      image: autoone3,
      "subtitle": "Adaptable to Any Environment",
      "description": "Whether you have an on-premise, cloud, or hybrid environment, Trivont Automation seamlessly adapts to your setup. Embrace the flexibility to manage your systems regardless of the infrastructure."
    },
    {
      "title": "Cloud Automation",
      image: autoone4,
      "subtitle": "Effortless Cloud Management",
      "description": "Leveraging the power of Trivont Automation, you can effortlessly automate cloud-related tasks on leading platforms such as AWS, Azure, and Oracle Cloud. Say goodbye to manual cloud management and embrace the efficiency of automation."
    },
    {
      "title": "Built-in AI Functionality",
      image: autoone5,
      "subtitle": "Advanced AI for Enhanced Productivity",
      "description": "Trivont Automation incorporates advanced AI capabilities that empower you to create automation tasks in a matter of seconds. This powerful feature not only saves time but also enhances productivity in your IT operations."
    },
    {
      "title": "Intelligent Event-Based Decision Making",
      image: autoone6,
      "subtitle": "Real-Time Analysis and Decision Making",
      "description": "One of the standout features of Trivont Automation is its powerful AI capabilities that enable event-based decision making during automation processes. Trivont Automation can analyze real-time data and detect known issues or potential bottlenecks that might arise during the automation workflow."
    },
    {
      "title": "Minimal Footprint",
      image: autoone7,
      "subtitle": "Optimized for Resource Efficiency",
      "description": "We understand the importance of resource efficiency. Trivont Automation has been optimized to require a minimal footprint, ensuring it doesn't burden your systems while delivering top-notch performance."
    },
    {
      "title": "Extensive System Compatibility",
      image: autoone8,
      "subtitle": "Compatible with a Wide Range of Systems",
      "description": "Trivont Automation is designed to work seamlessly with a wide range of systems, including RHEL, Ubuntu, Suse, Solaris, IBM AIX, VMware, Kubernetes, and Windows 2012 and above versions. Whatever your environment, Trivont Automation has got you covered."
    },
    {
      "title": "Integration Friendly - Change Request Automation",
      image: autoone9,
      "subtitle": "Streamlined Change Request Automation",
      "description": "Trivont Automation takes integration to the next level by offering Change Request automation. With this feature, you can seamlessly generate Change Requests within your integrated IT Service Management (ITSM) tools like BMC Remedy and ServiceNow directly from the Trivont Automation platform."
    },
    {
      "title": "Self-Service Portal Integration for Self-Provisioning",
      image: autoone10,
      "subtitle": "Empowering End-Users with Self-Provisioning",
      "description": "Trivont Automation opens up new possibilities for customers by offering seamless integration with their Self-Service Portals. With this powerful feature, end-users can initiate self-provisioning of resources and services with just a few clicks, without requiring direct involvement from IT personnel."
    }
  ];
  
  

    return (
        <>
            <Navbar handler={{handleSearch, handleOffcanvas}} headerTop="True"  />
            {/* <CustomSection header="Automation & AI"
  content="Designed to be an indispensable tool for IT professionals. Simplify your IT operations, improve productivity, and ensure seamless, auditable automation with Taniyangi's cutting-edge features"
 cImage={AI}
  />        */}
    <SliderHeroSection backgroundImage='#0d1e67'  title="Trivont Automation: Your Ultimate Configuration Management and Orchestration Solution" subtitle="Welcome to the world of Trivont Automation, the cutting-edge automation platform that brings the power of configuration management and orchestration together, all in one seamless package. Designed to be an indispensable tool for IT professionals, Trivont Automation empowers you to achieve efficient and auditable automation for both UNIX and Wintel systems, without the need for any agent installations" 
  textColor='#ffff' isDefault={false} featureTitle='Taniyangi' featureSubtitle='Welcome to the world of Taniyangi, the cutting-edge automation platform that brings the power of configuration management and orchestration together, all in one seamless package. Designed to be an indispensable tool for IT professionals, Taniyangi empowers you to achieve efficient and auditable automation for both UNIX and Wintel systems, without the need for any agent installations.
  '>
    
  </SliderHeroSection>
 


<IconBox items={features}/>

<ImageContainer  title={"Efficient Workflow and Task Automation for UNIX and Wintel Systems."}    src={taniyangi}
        alt="Screenshot of the automation software"  />
 
<BSsection4s
  heading="Welcome to the world of Taniyangi"
  title1="Unlock Seamless Automation"
  title2="Embrace AI-powered Workflows"
  title3="Looking for Expert Guidance?"
  desc1="Discover Taniyangi, the state-of-the-art automation platform designed to streamline your processes and achieve unparalleled efficiency."
  desc2="Our seasoned professionals are ready to guide you in implementing AI-driven workflows that propel your organization forward."
  buttonText1="Start Your Automation Journey"
  buttonText2="Consult with Our AI Experts"
/>


            <Footer data={footer} />
        </> 
    )
}

export default AutomationAI
